import * as React from "react";
import { useEffect } from "react";
import "./guide.scss";
import { parseHTML } from "../../helpers/parseHTML"
import {
  SEOMetaTags,
  TitleSection,
  CallToAction,
  GreyFooter,
  FundingBanner,
} from "../../components";
import MainMenu from "../../components/MainMenu/MainMenu";
import { IGuidePage } from "../interfaces";

const Guide = (data: IGuidePage): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  const { guide, guides, seoMetaTags, menu, lang, footer, cta, c2c, pageTitle, banner } = data.pageContext;

  const getSlug = (lang) => {
    switch (lang) {
      case "pl":
        return "poradnik";
      case "en":
        return "user-guide";
      case "es":
        return "guia";
      default:
        return "user-guide";
    }
  };

  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "user-guide");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;

  const allSlugLocales = guide && guide._allSlugLocales;
  const alternateLocaleData = allSlugLocales && allSlugLocales.filter(el => el.locale !== lang);
  const alternateLinks = alternateLocaleData && alternateLocaleData.map(el => el && {
    locale: el.locale,
    link: `https://pushpushgo.com/${el.locale}/${getSlug(el.locale)}/${el.value}/`,
  });

  return (
    <>
      <MainMenu menu={menu} lang={lang} c2c={c2c}/>
      <SEOMetaTags lang={lang} tags={seoMetaTags} pageName={pageName} alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: `${pageName}` }, {
        key: "blog",
        value: "blog-version",
      }, { key: "color", value: "green-version" }, { key: "paragraph", value: `${description}` }]}/>
      <div className="content-limiter user-guide-container">
        <div className="user-guide-questions">
          {guides.map(guideTitle => {
            return <a key={Math.random() + new Date().toDateString()}
                      className={`${guideTitle.category && guideTitle.category.name === guideTitle.title ? "category" : ""} 
                                  ${guideTitle.slug === guide.slug ? "active" : ""}`}
                      href={`/${lang}/${getSlug(lang)}/${guideTitle.slug}`}>{guideTitle.title}</a>;
          })}
        </div>
        <div className="user-guide-answers">
          <h1>{guide.title}</h1>
          <div>{parseHTML(guide.content)}</div>
        </div>
      </div>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};

export default Guide;
